<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按商品名称、SKU、分类名称搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['80001']"
          color="primary"
          dark
          @click="createBtnClick"
        >
          添加商品
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="totalDesserts"
        :loading="loading"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
        @update:sort-by="getDesserts"
        @update:sort-desc="getDesserts"
      >
        <template v-slot:item.cover_image="{ item }">
          <v-img
            :src="item.cover_image"
            max-height="32"
            max-width="32"
          ></v-img>
        </template>
        <template v-slot:item.track_inventory="{ item }">
          <v-icon
            :color="item.track_inventory ? 'success' : 'error'"
            dark
          >
            {{ item.track_inventory ? icons.mdiCheckCircle : icons.mdiCloseCircle }}
          </v-icon>
        </template>
        <template v-slot:item.is_publish="{ item }">
          <v-icon
            :color="item.is_publish ? 'success' : 'error'"
            dark
          >
            {{ item.is_publish ? icons.mdiCheckCircle : icons.mdiCloseCircle }}
          </v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['50002']"
            icon
            @click="editBtnClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['50003']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getGoods,
  deleteGood,
} from '@/api/goods'
import {
  mdiMagnify, mdiArrowRightCircle, mdiDelete, mdiCheckCircle, mdiCloseCircle,
} from '@mdi/js'

export default {
  name: 'Goods',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      deleteDialog: false,
      rowId: null,
      loading: true,
      headers: [
        { text: '封面图', value: 'cover_image', sortable: false },
        { text: '商品名称', value: 'name' },
        { text: 'SKU', value: 'sku' },
        { text: '统一库存管理', value: 'track_inventory' },
        { text: '是否发布', value: 'is_publish' },
        { text: '所属分类', value: 'category_name' },
        { text: '创建时间', value: 'created_at' },
        { text: '更新时间', value: 'updated_at' },
        {
          text: '操作',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
      sortFieldsMap: {
        sku: 'sku__sku',
        category_name: 'category__name',
      },
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCheckCircle,
        mdiCloseCircle,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
      sortBy: '',
      sortDesc: false,
    }
  },

  computed: {
    filterOptions() {
      if (this.sortBy) {
        const sort = this.sortFieldsMap[this.sortBy] || this.sortBy
        const ordering = this.sortDesc ? `-${sort}` : `${sort}`
        return {
          ordering,
          ...this.options,
        }
      }
      return this.options
    },
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      if (this.sortDesc === undefined) {
        return
      }
      this.loading = true
      getGoods(this.filterOptions).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    editBtnClick(item) {
      this.$router.push({ name: 'goodsDetail', query: { id: item.id } })
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteGood(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    createBtnClick() {
      this.$router.push({ name: 'goodsAdd' })
    },
  },
}
</script>
